
export default {
  methods: {
    on() {
      this.classe = "show";
    },
    off() {
      this.classe = "";
    },
    goTO(url) {
      this.$router.push(url);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.toggle = false;
      }
    },
  },
  created() {
    this.isMobile();
 
  },

  data() {
    return {
      classe: "",
      toggle: true,
    };
  },
};


// @ is an alias to /src test env
import { reactive, ref } from "vue";
import * as moment from "moment";
import Swal from "sweetalert2";
import Auth from "../../services/routes";
import Header from "../../components/Header/index.vue";
import PDF from "../../components/PDF/index.vue";
import lodash from "lodash";
import Multiselect from "@vueform/multiselect";
import { QuillEditor } from "@vueup/vue-quill";
import { Base64 } from "js-base64";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  name: "Home",
  components: { Header, Multiselect, QuillEditor, PDF },
  data() {
    return {
      oito: false,
      seis: false,
      MusculosSelecionados: [],
      value: [],
      descricao: "",
      categoria: "",
      categorias: "",
      Musculo: "",
      Musculos: "",
      Equipamento: "",
      Equipamentos: "",
      render: false,
      fileImage: null,
      showModal: false,
      fileName: false,
      activetab: 1,
      referencia: "",
      nome: null,
      imagem_musculo: null,
      load: false,
      tableData: [],
      API: null,
      expandRowKeys: [],
      pages: 1,
      tipo: 1,
      name_video: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 500, 1000],
        total: 0,
      },
    };
  },
  computed: {
    queriedData() {
      let result = lodash.orderBy(this.tableData, ["ID"], ["desc"]);
      /*
                                      let filtered = lodash.filter(result, item => {
                                        if(this.filter.filterDefault === "ALL") {
                                          return item.DES_FINANCE_CATEGORY.indexOf('') >=0;
                                        }
                                        else {
                                          return item.DES_FINANCE_CATEGORY.indexOf(this.filter.filterDefault) >=0;
                                        }  
                                      });

                                      this.tableDataFiltered = filtered
                                      */
      return result.slice(this.from, this.to);
    },
    pagedData() {
      if (!this.tableDataFiltered)
        return this.tableData.slice(this.from, this.to);
      else return this.tableDataFiltered.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      if (!this.tableDataFiltered)
        this.pagination.total = this.tableData.length;
      else this.pagination.total = this.tableDataFiltered.length;

      if (!this.tableDataFiltered) return this.tableData.length;
      else return this.tableDataFiltered.length;
    },
  },
  methods: {
    generate(dados) {
      if (dados.exercicios.length === 6) {
        this.generate6(dados);
      } else {
        this.generate8(dados);
      }
    },
    generate6(dados) {
      this.load = true;
      let treino = {
        nome: "Valeria Buoy",
        foto: null,
        treino: dados,
      };
      var dadosTable = JSON.stringify(treino);
      var encodedString = Base64.encode(dadosTable);
      this.dadosSend = encodedString;
      window.open(
        "https://desafiodaval.com.br/api_pdf/treino_6/index.html?dados=" +
          encodedString,
        "_blank"
      );
      this.seis = true;
      setTimeout(() => {
        this.load = false;
        this.seis = false;
      }, 3000);
    },
    generate8(dados) {
      this.load = true;
      let treino = {
        nome: "Valeria Buoy",
        foto: null,
        treino: dados,
      };
      var dadosTable = JSON.stringify(treino);
      var encodedString = Base64.encode(dadosTable);
      this.dadosSend = encodedString;
       window.open(
        "https://desafiodaval.com.br/api_pdf/treino_8/index.html?dados=" +
          encodedString,
        "_blank"
      );
      console.log(encodedString);
      this.oito = true;
      setTimeout(() => {
        this.load = false;
        this.oito = false;
      }, 3000);
    },
    open(dados) {
      console.log(dados);
    },
    addMusculo() {
      var obj = {};
      let i = 0;
      for (; i <= this.Musculos.length - 1; i++) {
        if (this.Musculos[i].nome === this.value) {
          obj.id = this.Musculos[i].id;
          obj.nome = this.Musculos[i].nome;
          obj.descricao =
            "1° SET DE 15 REPETIÇÕES 2º SET DE 15 REPETIÇÕES 3° SET DE 15 REPETIÇÕES 4° SET DE 15 REPETIÇÕES";
        }
      }
      this.MusculosSelecionados.push(obj);
    },
    handleExpandChange(row, expandedRows) {
      const id = row.idParaOrganizar;
      const lastId = this.expandRowKeys[0];
      // disable mutiple row expanded
      this.expandRowKeys = id === lastId ? [] : [id];
    },
    clicker() {},
    rowClicked(row) {
      this.$refs.tableData.toggleRowExpansion(row);
    },
    showFile() {
      this.load = true;
      var file = this.$refs.loadImage.files[0];
      this.name_video = this.$refs.loadImage.files[0].name;
      const getBase64 = (file) =>
        new Promise(function (resolve, reject) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject("Error: ", error);
        });
      getBase64(file)
        .then((result) => {
          this.render = result;
          this.load = false;
        })
        .catch((e) => console.log("deu erro:", e));
    },
    SendImage() {
      this.load = true;
      let data = {
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
        nome: this.nome,
        imagem: this.render,
        descricao: this.descricao,
        exercicios: this.MusculosSelecionados,
      };
      Auth.CadastroTreino(data)
        .then((r) => {
          if (r.data.resultado === false) {
            this.$notify({
              message: "Login Expirado",
              title: "Falha!",
              type: "Error",
            });
            Auth.logout();
          }
          if (r.data.resultado === "Treino Cadastrado") {
            this.$notify({
              message: "Cadastrado com Sucesso!",
              title: "Sucesso",
              type: "success",
            });
            this.pages = 1;
            this.render = false;
            this.descricao = "";
            this.MusculosSelecionados = [];
          } else {
            this.$notify({
              message: "Algo deu errado",
              title: "Falha!",
              type: "Error",
            });
          }
        })
        .catch((e) => {
          this.$notify({
            message: "Não foi possivel fazer esta ação",
            title: "Erro",
            type: "danger",
          });
        })
        .finally(() => {
          this.showModal = false;
          this.load = false;
          this.getItens();
        });
    },
    getItens() {
      this.load = true;
      Auth.getTreinos()
        .then((r) => {
          this.tableData = r.data.reverse();
          let i = 0;
          for (; i <= this.tableData.length - 1; i++) {
            this.tableData[i].idParaOrganizar = i;
          }
        })
        .finally(() => {
          this.load = false;
        });
    },
    getCategorias() {
      this.load = true;
      Auth.getCategoria()
        .then((r) => {
          this.categorias = r.data.categorias.reverse();
        })
        .finally(() => {
          this.load = false;
        });
    },
    getExercicios() {
      this.load = true;
      Auth.getExercicios()
        .then((r) => {
          this.Musculos = r.data.reverse();
          let i = 0;
          for (; i <= this.Musculos.length - 1; i++) {
            this.Musculos[i].value = this.Musculos[i].nome;
          }
        })
        .finally(() => {
          this.load = false;
        });
    },
    getEquipamentos() {
      this.load = true;
      Auth.getCategoria()
        .then((r) => {
          this.categorias = r.data.categorias.reverse();
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(row) {
      this.load = true;
      let data = {
        id: row.id,
        tabela: 3,
      };
      Auth.deleteItem(data)
        .then((r) => {
          this.$notify({
            message: "Deletado com Sucesso!",
            title: "Sucesso",
            type: "success",
          });
        })
        .finally(() => {
          this.load = false;
          this.getItens();
        });
    },
    exclude(index) {
      let products = this.MusculosSelecionados;
      products.splice(index, 1);
    },
  },
  mounted() {},
  created() {
    this.getItens();
    this.getCategorias();
    this.getExercicios();
    Auth.defaultW().then((r) => {
      this.API = r;
    });
  },
  setup() {
    const state = reactive({
      testes: "dCasa",
      numero: ref(0),
    });

    return {
      state,
    };
  },
};
